<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<div v-if="permissionName == 'assistant_manager'">
								{{ "Assistant Manager" }}
							</div>
							<div v-else>{{ permissionName }}</div>
						</h2>
					</div>
				</div>
			</div>
		</div>
		<b-card>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Search -->
					<b-col cols="12" md="6"> </b-col>
					<b-col cols="12" md="6" class="mt-2 d-flex justify-content-end align-items-center">
						<div>
							<b-form-input type="text" class="form-control" placeholder="Search" @input="getData()"
								v-model.trim="search_filter.input_field" style="border-radius: 4px" />
						</div>
						<b-button @click="clearFilter" variant="secondary
 			 ml-1">
							Clear
						</b-button>
						<b-button variant="primary mr-1 ml-2" @click="addList()"> Add </b-button>
					</b-col>
				</b-row>
			</div>
			<!-- source modal -->
			<b-modal id="add-source" title="Add Source" ok-only ok-title="Submit">
				<form ref="form" @submit.stop.prevent="handleSubmit">
					<b-form-group label="Enter Source Name">
						<div class="form-label-group">
							<b-form-input id="candlingName" placeholder="Enter Source Name" />
						</div>
					</b-form-group>
				</form>
			</b-modal>
			<div v-if="users && users.total > 0">
				<b-table responsive id="userList" :items="users.data" :fields="fields" class="mb-0">
					<template #cell(index)="data">
						<div class="ml-1">
							{{ users.from + data.index }}
						</div>
					</template>
					<template #cell(status)="data">
						<div v-if="data.value === 'Active'" class="activeClass">Active</div>
						<div v-else class="inactiveClass">Inactive</div>
					</template>
					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<div class="text-nowrap">
							<!-- <router-link
              class="text-secondary ml-2"
              :to="{ path: `/apps/expense-entry/${data.item.id}` }"
            >
              <feather-icon icon="EditIcon" size="16"
            /></router-link> -->
							<div class="d-flex justify-content-start">
								<div class="text-nowrap ml-2">
									<feather-icon icon="EditIcon" size="16" @click="() => editList(data.item.id)" />

									<!-- {{data.item}} -->
									<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />
								</div>
								<div class="text-nowrap ml-2">
									<feather-icon icon="EyeIcon" size="16" @click="() => viewList(data.item.id)" />

									<!-- {{data.item}} -->
									<b-tooltip title="View" :target="`invoice-row-${data.item.id}-preview-icon`" />
								</div>
							</div>
						</div>
					</template>
				</b-table>
				<div class="mt-2 mb-1">
					<b-pagination :value="users.current_page" :total-rows="users.total" :per-page="users.per_page"
						align="right" @change="getData">
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
				<h4 class="mt-2 Secondary">No user found</h4>
			</div>
		</b-card>
	</div>
</template>

<script>
import AddForm from "./Add-form.vue";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
export default {
	components: {
	},
	props: {
		permissionName: {
			type: String,
		},
	},

	data() {
		return {
			page: null,
			fields: ["index", "name", "email", "user_name", "status", "Actions"],
			users: [],
			status: [
				{
					Active: "light-success",
					Inactive: "light-warning",
				},
			],
			search_filter: {
				input_field: "",
			},
		};
	},
	created: function () {
		this.getData();
	},
	computed: {
		rows() {
			return this.users.length;
		},
	},
	watch: {
		deep: true,
		immediate: true,
		permissionName(val, oldVal) {
			if (val !== oldVal) {
				this.getData();
			}
		},
	},
	methods: {
		getData(pageNo = 1) {
			if (this.permissionName == "AssistantManager") {
				this.permissionName = "assistant_manager";
			}
			let url = "";
			let companyId = this.$route.params.companyId;
			let queryParams = {
				page: pageNo,
			};

			if (companyId) {
				url = `web/company/${companyId}/getUser/${this.permissionName.toLowerCase()}`;
			} else {
				url = `web/getUser/${this.permissionName.toLowerCase()}`;
			}

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
				queryParams.role = `${this.permissionName.toLowerCase()}`;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.permissionName == "AssistantManager";
					this.users = response.data.user;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addList() {
			if (this.permissionName == "assistant_manager") {
				this.permissionName = "assistant";
			}
			if (this.$route.params.companyId) {
				this.$router.push({
					path: `/apps/manage-company/${this.$route.params.companyId
						}/${this.permissionName.toLowerCase()}/add`,
				});
			} else {
				this.$router.push({
					path: `/apps/${this.permissionName.toLowerCase()}/add`,
				});
			}
		},
		formatRoleName() {
			let name = "";
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.getData();
		},
		editList(id) {
			if (this.permissionName === "assistant_manager") {
				this.permissionName = "assistant";
			}
			this.$router.push({
				path: `/apps/${this.permissionName.toLowerCase()}/edit/${id}`,
			});
		},
		viewList(id) {
			if (this.permissionName === "assistant_manager") {
				this.permissionName = "assistant";
			}
			this.$router.push({
				path: `/apps/${this.permissionName.toLowerCase()}/view/${id}`,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	border-radius: 10px;
	justify-content: center;
}

.inactiveClass {
	background-color: #ea5455;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	justify-content: center;
	border-radius: 10px;
}
</style>
